@mixin make-colors() {

  @each $colorName, $color in $colors {

    .app-#{$colorName} {
      background-color: $color !important;
    }

    .app-#{$colorName}-color {
      color: $color !important;
    }

    .app-#{$colorName}-border-color {
      border-color: $color !important;
    }
  }
}

$colors: (
  'white-lotion': #FCFDFD,
  'eerie-black': #1E1E1E,
  'stormcloud': #4F5B67,
  'cultured': #F3F5F7,
  'philippine_silver': #AFB3B5,
  'anti-flash-white': #F1F1F1,
  'dark-silver': #6F6F6F,
  'light-silver': #D0D5DD,
  'azure': #0084FF,
  'light-colar': #EE7D7D,
  'tart-orange': #FA4B4B,
  'pastel-green': #79CF7C,
  'khaki-yellow': #F0E08E,
);

$color-white-lotion: #FCFDFD;
$color-eerie-black: #1E1E1E;
$color-stormcloud: #4F5B67;
$color-cultured: #F3F5F7;
$color-philippine_silver: #AFB3B5;
$color-anti-flash-white: #F1F1F1;
$color-dark-silver: #6F6F6F;
$color-light-silver: #D0D5DD;
$color-azure: #0084FF;
$color-light-colar: #EE7D7D;
$color-tart-orange: #FA4B4B;
$color-pastel-green: #79CF7C;
$color-khaki-yellow: #F0E08E;
