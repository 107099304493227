@mixin make-fonts() {
  @font-face {
    font-family: "Segoe UI Bold";
    src: url("../assets/font/SegoeUI-Bold.eot") format('eot');
    src: url("../assets/font/SegoeUI-Bold.ttf") format('ttf');
    src: url("../assets/font/SegoeUI-Bold.woff") format('woff');
  }
  @font-face {
    font-family: "Segoe UI Semibold";
    src: url("../assets/font/SegoeUI-SemiBold.eot") format('eot');
    src: url("../assets/font/SegoeUI-SemiBold.ttf") format('ttf');
    src: url("../assets/font/SegoeUI-SemiBold.woff") format('woff');
  }
  @font-face {
    font-family: "Segoe UI Regular";
    src: url("../assets/font/SegoeUI.eot") format('eot');
    src: url("../assets/font/SegoeUI.ttf") format('ttf');
    src: url("../assets/font/SegoeUI.woff") format('woff');
  }
}

.text-22 {
  font-size: 22px;
  line-height: 22px;
}

.text-18 {
  font-size: 18px;
  line-height: 18px;
}

.text-16 {
  font-size: 16px;
  line-height: 16px;
}

.text-14 {
  font-size: 14px;
  line-height: 20px;
}
.text-12 {
  font-size: 12px !important;
  line-height: 14px;
}

.text-10 {
  font-size: 10px;
  line-height: 12px;
}

.text-700 {
  font-family: 'Segoe UI Bold' ;
}
.text-400 {
  font-family: 'Segoe UI Regular' ;
}
.text-600 {
  font-family: "Segoe UI Semibold";
}

