.flex-row            { flex-direction: row !important; }
.flex-column         { flex-direction: column !important; }
.flex-row-reverse    { flex-direction: row-reverse !important; }
.flex-column-reverse { flex-direction: column-reverse !important; }

.flex-wrap         { flex-wrap: wrap !important; }
.flex-nowrap       { flex-wrap: nowrap !important; }
.flex-wrap-reverse { flex-wrap: wrap-reverse !important; }
.flex-fill         { flex: 1 1 auto !important; }
.flex-grow-0       { flex-grow: 0 !important; }
.flex-grow-1       { flex-grow: 1 !important; }
.flex-shrink-0     { flex-shrink: 0 !important; }
.flex-shrink-1     { flex-shrink: 1 !important; }

.justify-content-start   { justify-content: flex-start !important; }
.justify-content-end     { justify-content: flex-end !important; }
.justify-content-center  { justify-content: center !important; }
.justify-content-between { justify-content: space-between !important; }
.justify-content-around  { justify-content: space-around !important; }
.justify-content-evenly  { justify-content: space-evenly !important; }

.align-items-start    { align-items: flex-start !important; }
.align-items-end      { align-items: flex-end !important; }
.align-items-center   { align-items: center !important; }
.align-items-baseline { align-items: baseline !important; }
.align-items-stretch  { align-items: stretch !important; }

.align-content-start   { align-content: flex-start !important; }
.align-content-end     { align-content: flex-end !important; }
.align-content-center  { align-content: center !important; }
.align-content-between { align-content: space-between !important; }
.align-content-around  { align-content: space-around !important; }
.align-content-stretch { align-content: stretch !important; }

.align-self-auto     { align-self: auto !important; }
.align-self-start    { align-self: flex-start !important; }
.align-self-end      { align-self: flex-end !important; }
.align-self-center   { align-self: center !important; }
.align-self-baseline { align-self: baseline !important; }
.align-self-stretch  { align-self: stretch !important; }

.d-flex { display: flex !important; }
.d-block { display: block !important; }
.d-inline-block { display: inline-block !important; }
.d-none { display: none !important; }
.d-table { display: table !important; }
.d-table-row { display: table-row !important; }
.d-table-cell { display: table-cell !important; }
.d-inline-flex { display: inline-flex !important; }

.overflow-auto { overflow: auto !important; }
.overflow-hidden { overflow: hidden !important; }
.overflow-visible {overflow: visible !important;}
.overflow-scroll {overflow: scroll !important;}

.position-static { position: static !important; }
.position-relative { position: relative !important; }
.position-absolute { position: absolute !important; }
.position-fixed { position: fixed !important; }
.position-sticky { position: sticky !important; }

.user-select-all { user-select: all !important; }
.user-select-auto { user-select: auto !important; }
.user-select-none { user-select: none !important; }

.cursor-pointer {cursor:pointer !important;}
.cursor-grab {cursor: grab !important;}

.w-100 { width:100% !important; }
.w-50 { width:50% !important; }
.h-100 { height:100% !important; }

.b-r-5 { border-radius: 5px }

/*TODO: добавить паддинг-классы*/
.p-0 {padding: 0 !important;}
.p-0 {padding: 0 !important;}
.pb-0 {padding-bottom: 0 !important;}
.pt-0 {padding-top: 0 !important;}
.pt-10 { padding-left: 10px }

.opacity-0 {opacity:0 !important;}


