@use '@angular/cdk' as cdk;
@use "@worktile/gantt/styles/index.scss";

@import "styles/colors";
@include make-colors();

@import "styles/offsets";
@include make-offsets();

@import "styles/fonts";
@include make-fonts();

@import "styles/borders";
@import "styles/bootstrap-utulities";

@include cdk.overlay();

html, body { height: 100%; }
body {
  margin: 0;
  font-family: 'Segoe UI';
  font-weight: 400;

}

body.dragging {
  cursor: grabbing !important;
}

.cdk-drag-handle {
  & mat-icon {
    cursor: grab !important;
    &:active {
      cursor: grabbing !important;
    }
  }
}

.cdk-drop-list-dragging {
  cursor: grabbing !important;
}

span {
  display:block
}
div, span, p, button, aside {
  box-sizing: border-box;
}
.dialog {
  mat-dialog-container {
    .mdc-dialog__surface {
      border-radius: 15px;
    }
  }
}
.svg-container{
  width: 24px;
  height: 24px;
}
.highlight {
  height: 20px;
  background-color: rgba(map-get($colors, "pastel-green"), 0.2);
  padding-left: 2px;
  padding-right: 2px;
  position: relative;
}

.highlight::before,
.highlight::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: rgba(map-get($colors, "pastel-green"), 1); /* Цвет полосок */
}

.highlight::before {
  left: 0;
}

.highlight::after {
  right: 0;
}
:root{
  --mat-table-row-item-container-height: 30px
}
@keyframes opacityAnimate {
  to {
    opacity: 1;
  }
}
