$offsets: (
  '4px': 4px,
  '8px': 8px,
  '10px': 10px,
  '12px': 12px,
  '15px': 15px,
  '16px': 16px,
  '20px': 20px,
  '24px': 24px,
  '32px': 32px,
  '40px': 40px,
  '48px': 48px,
  '64px': 64px,
  '70px': 70px,
  '90px': 90px,
  '220px': 220px,
);
@mixin make-offsets() {
  @each $offsetName, $offset in $offsets {
    .app-mt-#{$offsetName} {
      margin-top: $offset !important;
    }

    .app-mb-#{$offsetName} {
      margin-bottom: $offset !important;
    }

    .app-ml-#{$offsetName} {
      margin-left: $offset !important;
    }

    .app-mr-#{$offsetName} {
      margin-right: $offset !important;
    }
  }
}
